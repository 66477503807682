import React, { useEffect, useReducer, useState } from 'react'
import Thumbnails from '../../components/Thumbnails/Thumbnails';
import { getOfferWalls } from '../../services/offerWallService';
import classes from './offersPage.module.css';
import { Link } from 'react-router-dom';
import StarRating from '../../components/StarRating/StarRating';
import Button from '../../components/Button/Button';
import { getRevlumOffers } from '../../services/revlumService';
import { getWannadsOffers } from '../../services/wannadsService';
import { getUserGeo } from '../../services/userGeoService';
import { getCpaLeadOffers } from '../../services/cpaLeadService';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WallOffer from '../../components/WallOffer/WallOffer';
import TopOffer from '../../components/TopOffer/TopOffer';
import { getAdscendOffers } from '../../services/adscendService';
import { getLootablyGamingOffers } from '../../services/lootablyService';


const initialState = { offerWalls: [] };

const reducer = (state, action) => {
    switch (action.type) {
        case 'OFFERWALLS_LOADED':
            return { ...state, offerWalls: action.payload };
        default:
            return state;
    }
}
export default function OffersPage() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user } = useAuth();
    const { offerWalls } = state;
    const [ip, setIP] = useState("");
    const [offers, setOffers] = useState([]);
    const [featuredOffers, setFeaturedOffers] = useState([]);
    const [country, setCountry] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchIPAndCountry = async () => {
          try {
              const ipRes = await axios.get("https://api.ipify.org/?format=json");
              const fetchedIP = ipRes.data.ip;
              setIP(fetchedIP);

              const geoRes = await getUserGeo(fetchedIP);
              const fetchedCountry = geoRes.country_code2;
              setCountry(fetchedCountry);

              console.log("User IP is " + fetchedIP);
              console.log("User country is " + fetchedCountry);
          } catch (error) {
              setError(error.message ? error.message : JSON.stringify(error));
              setLoading(false);
          }
      };

      fetchIPAndCountry();
  }, []);

  useEffect(() => {
      const fetchOffers = async () => {
          if (user.id && ip && country) {
              try {
                  const [revlumData, lootablyData] = await Promise.all([
                      getRevlumOffers(country),
                      getLootablyGamingOffers()
                  ]);

                 /* const transformAdscendOffers = adscendData.map(offer => ({
                    id: offer.offer_id,
                    title: offer.name,
                    description: offer.description,
                    imageUrl: offer.image_url,
                    link: offer.click_url,
                    reward: offer.currency_count
                }));*/

                    const lootablyArray = lootablyData.data.offers;
                    const sortedOffers = lootablyArray.sort((a, b) => b.conversionRate - a.conversionRate);
                    const top30Offers = sortedOffers.slice(0, 30);
                    const transformLootablyOffers = top30Offers.map(offer => {
                        let reward;
                    
                        if (offer.type === "singlestep") {
                            reward = offer.currencyReward;
                        } else if (offer.type === "multistep") {
                            reward = offer.goals.reduce((sum, goal) => sum + goal.currencyReward, 0);
                        }

                        let events;
                        if (offer.type === "singlestep") {
                            events = [{
                                eventDescription: offer.description,
                                value: offer.currencyReward
                            }];
                        } else {
                            events = offer.goals.map(goal => ({
                                eventDescription: goal.description,
                                value: goal.currencyReward
                            }));
                        }
                        return {
                            id: offer.offerID,
                            title: offer.name,
                            description: offer.description,
                            imageUrl: offer.image,
                            link: offer.link.replace("{userID}", user.id),
                            reward: Number(reward),
                            events: events
                        };
                    });

                  const transformRevlumOffers = revlumData.map(offer => {
                      let url = new URL(offer.url);
                      url.searchParams.set('userId', user.id);
                      url.searchParams.delete('sub1');

                      let events;
                      events = offer.event.map(event => ({
                            eventDescription: event.name,
                            value: Number(event.payout) * 100 * 0.60
                        }));

                      return {
                          id: offer.offerID,
                          title: offer.title,
                          description: offer.description,
                          imageUrl: offer.image,
                          link: url.toString(),
                          reward: Number(offer.payout) * 100 * 0.60,
                          events: events
                      }
                  });

              const allOffers = [...transformRevlumOffers, ...transformLootablyOffers];
              const uniqueOffers = Array.from(new Set(allOffers.map(offer => offer.title)))
                                  .map(title => allOffers.find(offer => offer.title === title));
              setOffers(uniqueOffers);
              } catch (error) {
                  setError(error.message ? error.message : JSON.stringify(error));
              } finally {
                  setLoading(false);
              }
          }
          };
          fetchOffers();
  }, [user.id, ip, country]);

    useEffect(() => {
        getOfferWalls().then(offerWalls => dispatch({ type: 'OFFERWALLS_LOADED', payload: offerWalls}));
    }, []);

    const topOffers = offers.sort((a, b) => b.reward - a.reward).slice(0, 25);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4
    };

    return (
        <div className={classes.pageContainer}>
        <div className={classes.categories}>
        <Button className={classes.button} text="Explore" color="black" backgroundColor="white" width="8rem" height="2rem" fontSize="1rem"/>
        <Button className={classes.button} text="Games" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/games'; }}/>
        <Button className={classes.button} text="Surveys" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/surveys'; }}/>
        <Button className={classes.button} text="Offers" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/offers'; }}/>

        </div>
        <h2 className={classes.sponsoredOffersHeading}>Best Offers</h2>
        <hr className={classes.line}/>
        <Slider {...settings}>
                {topOffers.map(offer => (
                    <div key={offer.id}>
                        <TopOffer
                            title_text={offer.title}
                            center_text={offer.description}
                            image_game={offer.imageUrl}
                            image_android={'/android.png'}
                            image_Trophy={'/trophy.png'}
                            link={offer.link}
                            reward_payout={offer.reward}
                            events={offer.events}
                        />
                    </div>
                ))}
          </Slider>
        <h2 className={classes.sponsoredOffersHeading}>Offer Partners</h2>
        <hr className={classes.line}/>
        <Thumbnails offerWalls={offerWalls}/>
        <h2 className={classes.sponsoredOffersHeading}>Survey Partners</h2>
        <hr className={classes.line}/>
        <ul className={classes.list}>

        <li key={'cpx'}>
            <Link to={`/survey/cpx`}>
                <img
              className={classes.image}
              src={`logo2-cpx-reserach.png`}
              alt={"CPX Research"}
            />
          <div className={classes.content}>
            <div className={classes.stars}>
                <StarRating stars={5} />
            </div>
          </div>
          </Link>
        </li>
    </ul>
    </div>
    )
}